import React, { Component } from 'react';
import Carousel from '../components/carousel';
import Divider from '../components/divider';
import allPaths from './all-paths.module.scss';

class AllPathsPage extends Component {
  render() {
    const description = (
      <>
        <h2>All paths brought us here</h2>
        <p>
          During our lives we struggle to let go of the past and move on. The decisions and thoughts of what could have been if we had chosen differently come back to haunt us at every step. To shed this heavy burden, we seek answers from the moon, Gods, parallel dimensions and destiny in order to understand and to be ready for the next time. We live in fear, trying to make the right decisions to avoid regret.
        </p>
        <p>
          This is a journey about acceptance, coming to terms with the past and moving on. It is a visual metaphor about our feelings and their reflections into nature manifestations. From the darkest place to the revelation that all possible futures are happening now, it is time to let go and look ahead because everything has guided us to this very moment.
        </p>
        <Divider align="right" />
        <p className="smaller">
          <i>All paths brought us here</i> was selected from Wales Arts International for the collaboration between Leonardo Couto and the writer <a href="//www.durreshahwar.com/" rel="noreferrer" target="_blank"><u>Durre Shahwar</u></a>.
        </p>
      </>
    );

    return (
      <Carousel
        pageName="allpaths"
        description={description}
        images={[
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/hand.JPG',
            className: allPaths.hand,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/hyperspace.JPG',
            className: allPaths.hyperspace,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/reality.JPG',
            className: allPaths.reality,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/eye.JPG',
            className: allPaths.eye,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/galaxy.JPG',
            className: allPaths.galaxy,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/hair.JPG',
            className: allPaths.hair,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/butterfly.JPG',
            className: allPaths.butterfly,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/snow-storm.JPG',
            className: allPaths.snowStorm,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/waterfall.JPG',
            className: allPaths.waterfall,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/fox.JPG',
            className: allPaths.fox,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/moon.JPG',
            className: allPaths.moon,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/prism.JPG',
            className: allPaths.prism,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/lines.JPG',
            className: allPaths.lines,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/carp.JPG',
            className: allPaths.carp,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/living-water.JPG',
            className: allPaths.livingWater,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/memory.JPG',
            className: allPaths.memory,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/flowers.JPG',
            className: allPaths.flowers,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/water-shine.JPG',
            className: allPaths.waterShine,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/tree.JPG',
            className: allPaths.tree,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/clouds.jpg',
            className: allPaths.clouds,
          },
        ]}
      />
    );
  }
}

export default AllPathsPage;
