import React, { Component } from 'react';
import Carousel from '../components/carousel';

class PortraitsPage extends Component {
  render() {
    return (
      <Carousel
        pageName="photographs"
        images={[
          'https://s3.eu-central-1.amazonaws.com/lc-portfolio/photographs/01.jpg',
          'https://s3.eu-central-1.amazonaws.com/lc-portfolio/photographs/02.jpg',
          'https://s3.eu-central-1.amazonaws.com/lc-portfolio/photographs/03.jpg',
          'https://s3.eu-central-1.amazonaws.com/lc-portfolio/photographs/04.jpg'
        ]}
      />
    );
  }
}

export default PortraitsPage;
