import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import './index.css';
import App from './app.js';
import * as serviceWorker from './serviceWorker';

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(<App />);

serviceWorker.unregister();
