import React, { Component } from 'react';
import Carousel from '../components/carousel';
import Divider from '../components/divider';
import everythingIsFine from './everything-is-fine.module.scss';

class EverythingIsFinePage extends Component {
  render() {
    const description = (
      <>
        <h2>Everything is fine</h2>
        <p>
          Something is here.
          <br />
          Creeping, blending,
          <br />
          becoming everything.
        </p>
        <p>
          I can see through.
          <br />
          Lights,
          <br />
          in pitch black.
        </p>
        <p>
          Feel again,
          <br />
          feel something.
          <br />
          Forgive me,
          <br />
          embrace me.
        </p>
        <p>
          I will stay,
          <br />
          just a bit more.
        </p>
        <Divider align="right" />
        <p>
          <i>Everything is fine</i> was part of the FUTR.1 exhibition in the FK
          Gallerie (Berlin, March 2019).
        </p>
      </>
    );

    return (
      <Carousel
        pageName="eif"
        description={description}
        images={[
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_01.jpg', className: everythingIsFine.square },
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_02.jpg', className: everythingIsFine.square },
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_03.jpg', className: everythingIsFine.square },
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_04.jpg', className: everythingIsFine.square },
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_05.jpg', className: everythingIsFine.square },
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_06.jpg', className: everythingIsFine.square },
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_07.jpg', className: everythingIsFine.square },
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_08.jpg', className: everythingIsFine.square },
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_09.jpg', className: everythingIsFine.square },
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_10.jpg', className: everythingIsFine.square },
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_11.jpg', className: everythingIsFine.square },
          { src: 'https://s3.eu-central-1.amazonaws.com/lc-portfolio/eif/eif_12.jpg', className: everythingIsFine.square }
        ]}
      />
    );
  }
}

export default EverythingIsFinePage;
