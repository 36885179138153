import React from 'react';
import copyright from './copyright.module.scss';

function Copyright({ className }) {
  return (
    <div className={`${copyright.wrapper} ${className}`}>
      <hr align="left" className={copyright.divider} />
      <div className={copyright.text}>
        All content copyright the artist. No use without express written
        permission.
      </div>
      <div>© Leonardo Couto {new Date().getUTCFullYear()}</div>
    </div>
  );
}

export default Copyright;
