import React, { Component } from 'react';
import textPage from '../components/shared/text-page.module.scss';

class AboutPage extends Component {
  render() {
    return (
      <div className={textPage.wrapper}>
        <h1>About</h1>
        <p>
          I was born and raised in the Rio de Janeiro where I started to photograph the streets in 2016.
          In 2017 I moved to Berlin and in 2018 my first zine was released, right after analog photography
          became my main medium. Same year I started to collaborate with
          FKKollektiv - a collective of international photographers in Berlin - where my images started to
          gain more depth and were exhibited for the first time in early 2019.
        </p>
        <h1 style={{ marginTop: '3rem' }}>Solo Exhibitions</h1>
        <ul>
          <li>
            <p>All paths brought us here</p>
            <p>2022 - Redah Art Haus, Berlin</p>
          </li>
        </ul>
        <h1 style={{ marginTop: '3rem' }}>Group Exhibitions</h1>
        <ul>
          <li>
            <p>Final Bloom</p>
            <p>2020 - FK Gallerie, Berlin</p>
          </li>
          <li>
            <p>Everything Home is Far Away</p>
            <p>2020 - FK Gallerie, Berlin</p>
          </li>
          <li>
            <p>FUTR.1</p>
            <p>2019 - FK Gallerie, Berlin</p>
          </li>
        </ul>
        <h1 style={{ marginTop: '3rem' }}>Publications</h1>
        <ul>
          <li>2018 - Contemplation Exercises</li>
        </ul>
        <h1 style={{ marginTop: '3rem' }}>Contact</h1>
        <p>
          For inquiries about editorials, portrait sessions and/or
          collaborations send me an e-mail:
        </p>
        <p>
          <b>contact@leonardocouto.com</b>
        </p>
      </div>
    );
  }
}

export default AboutPage;
