import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import menu from './menu.module.scss';
import Copyright from '../copyright';

function Menu() {
  const [expanded, setExpanded] = useState(false);
  const buttonStyle = cx('hamburger', 'hamburger--squeeze', 'no-select', {
    'is-active': expanded,
    [menu.button]: true
  });
  const sliderStyle = cx({
    [menu.slider]: true,
    [menu.slideIn]: expanded,
    [menu.slideOut]: !expanded
  });
  const renderItem = (url, description) => (
    <li>
      <Link to={url} onClick={() => setExpanded(false)}>
        {description}
      </Link>
    </li>
  );
  const renderList = () => (
    <ul>
      {renderItem('/all-paths', 'All paths brought us here')}
      {renderItem('/empty-fields', 'Empty Fields (On Going)')}
      {renderItem('/everything-is-fine', 'Everything is fine')}
      {renderItem('/contemplation-exercises', 'Contemplation Exercises')}
      {renderItem('/portraits', 'Portraits')}
      {renderItem('/about', 'About')}
      <li>
        <a
          href="//instagram.com/ditrospecta"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
      </li>
    </ul>
  );

  return (
    <>
      <div className={menu.hamburger}>
        <h1>LEONARDO COUTO</h1>
        <button
          className={buttonStyle}
          type="button"
          onClick={() => setExpanded(!expanded)}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </button>
      </div>
      <nav className={sliderStyle}>{renderList()}</nav>
      <nav className={menu.wrapper}>
        <div>
          <h1>
            <span className={menu.titleName}>LEONARDO</span>
            <span>COUTO</span>
          </h1>
          {renderList()}
        </div>
        <Copyright />
      </nav>
    </>
  );
}

export default Menu;
