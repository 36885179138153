import React, { Component } from 'react';
import Carousel from '../components/carousel';

class PortraitsPage extends Component {
  render() {
    return (
      <Carousel
        pageName="portraits"
        images={[
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p01.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p02.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p04.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p05.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p06.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p26.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p28.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p33.JPG' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p35.JPG' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p08.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p09.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p10.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p11.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p12.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p13.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p14.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p15.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p16.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p17.JPG' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p30.JPG' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p32.JPG' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p36.JPG' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p31.JPG' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p34.JPG' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p20.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p21.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p22.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/portraits/p23.jpg' },
        ]}
      />
    );
  }
}

export default PortraitsPage;
