import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Menu from './components/menu';
import PhotographsPage from './pages/photographs';
import PortraitsPage from './pages/portraits';
import PriceListPage from './pages/price-list';
import EverythingIsFinePage from './pages/everything-is-fine';
import ContemplationPage from './pages/contemplation-exercises';
import AllPathsPage from './pages/all-paths';
import EmptyFieldsPage from './pages/empty-fields';
import AboutPage from './pages/about-contact';

function App() {
  return (
    <Router>
      <Menu />
      <Route exact path="/" component={AboutPage} />
      <Route path="/photographs" component={PhotographsPage} />
      <Route path="/portraits" component={PortraitsPage} />
      <Route path="/everything-is-fine" component={EverythingIsFinePage} />
      <Route path="/contemplation-exercises" component={ContemplationPage} />
      <Route path="/all-paths" component={AllPathsPage} />
      <Route path="/empty-fields" component={EmptyFieldsPage} />
      <Route path="/price-list" component={PriceListPage} />
      <Route path="/about" component={AboutPage} />
    </Router>
  );
}

export default App;
