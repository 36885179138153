import React, { Component } from 'react';
import Carousel from '../components/carousel';
import emptyFields from './empty-fields.module.scss';

class EmptyFieldsPage extends Component {
  render() {
    return (
      <Carousel
        pageName="emptyfields"
        images={[
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/empty-fields/01.JPG',
            className: emptyFields.horizontalLarge,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/empty-fields/02.JPG',
            className: emptyFields.vertical,
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/empty-fields/03.JPG',
            className: emptyFields.horizontalMedium,
          },
        ]}
      />
    );
  }
}

export default EmptyFieldsPage;
