import React, { Component } from 'react';
import Carousel from '../components/carousel';

class ContemplationPage extends Component {
  render() {
    return (
      <Carousel
        pageName="contemplation"
        images={[
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/cexe/00.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/cexe/07.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/cexe/03.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/cexe/02.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/cexe/04.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/cexe/05.jpg' },
          { src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/cexe/08.jpg' },
        ]}
      />
    );
  }
}

export default ContemplationPage;
