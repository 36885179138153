import React, { PureComponent, Fragment } from 'react';
import lozad from 'lozad';
import carousel from './carousel.module.scss';
import Copyright from '../copyright';

class Carousel extends PureComponent {
  constructor() {
    super();
    this.state = {
      scrollLineHeight: this.getScrollLineHeight(),
      isChrome:
        !!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime),
      isFirefox: typeof InstallTrigger !== 'undefined'
    };
  }

  getScrollLineHeight() {
    let r;
    const iframe = document.createElement('iframe');
    iframe.src = '#';
    document.body.appendChild(iframe);
    const iwin = iframe.contentWindow;
    const idoc = iwin.document;
    idoc.open();
    idoc.write(
      '<!DOCTYPE html><html><head></head><body><span>a</span></body></html>'
    );
    idoc.close();
    const span = idoc.body.firstElementChild;
    r = span.offsetHeight;
    document.body.removeChild(iframe);

    return r;
  }

  listenToScroll = e => {
    if (!this.state.isChrome) return;

    const deltaByType = {
      0: e.deltaY,
      1: e.deltaY * this.state.scrollLineHeight
    };
    window.scrollTo({
      left: window.scrollX + deltaByType[e.deltaMode],
      behaviour: 'smooth'
    });
  };

  stopBubbling(e) {
    e.stopPropagation();
  }

  componentDidMount() {
    const observer = lozad();
    const scroller = document.getElementById('scroller');
    const description = document.getElementById('description');

    observer.observe();

    const qry = document.querySelectorAll('img');
    const images = [].slice.call(qry);

    images.forEach(img => {
      img.addEventListener('load', e => {
        const el = e.target;
        el.style.opacity = '1';
      });
    });

    scroller.addEventListener('wheel', this.listenToScroll);
    if (description) {
      description.addEventListener('wheel', this.stopBubbling);
    }
  }

  componentWillUnmount() {
    const scroller = document.getElementById('scroller');
    const description = document.getElementById('description');

    scroller.removeEventListener('wheel', this.listenToScroll);
    if (description) {
      description.removeEventListener('wheel', this.stopBubbling);
    }
  }

  render() {
    const { description, pageName, images, tags } = this.props;

    return (
      <div id="scroller" className={carousel.wrapper}>
        {description && (
          <div id="description" className={carousel.description}>
            {description}
          </div>
        )}
        {images.map(({ src, className }, index) => (
          <Fragment key={`${pageName}-${index}`}>
            <img
              className={className ? `lozad ${className}` : `lozad ${carousel.fullSize}`}
              src={src}
              alt=""
              style={{ opacity: 0 }}
            />
            {tags && <p key={tags[index]} className={carousel.tag}>{tags[index]}</p>}
          </Fragment>
        ))}
        <Copyright className={carousel.copyright} />
      </div>
    );
  }
}

export default Carousel;
