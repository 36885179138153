import React, { Component } from 'react';
import Carousel from '../components/carousel';

class AllPathsPage extends Component {
  render() {
    const tagHand = 'Code: 001 | Size: A4 | Price: 125€';
    const tagHyperspace = 'Code: 002 | Size: A5 | Price: 75€';
    const tagReality = (<><p>Code: 003 | Size: 106x160cm | Price: 350€</p><p style={{ lineHeight: 0.25 }}>* Frame not included</p></>);
    const tagEye = 'Code: 004 | Size: A5 | Price: 75€';
    const tagGalaxy = 'Code: 005 | Size: A2 | Price: 225€';
    const tagHair = 'Code: 006 | Size: A3 | Price: 175€';
    const tagButterfly = 'Code: 007 | Size: A3 | Price: 175€';
    const tagSnowstorm = (<><p>Code: 008 | Size: A1 | Price: 250€</p><p style={{ lineHeight: 0.25 }}>* Frame not included</p></>);
    const tagWaterfall = 'Code: 009 | Size: A3 | Price: 175€';
    const tagFox = 'Code: 010 | Size: A2 | Price: 225€';
    const tagPrism = 'Code: 011 | Size: A5 | Price: 75€';
    const tagLines = 'Code: 012 | Size: A5 | Price: 100€';
    const tagCarp = 'Code: 013 | Size: A4 | Price: 125€';
    const tagLivingWater = 'Code: 014 | Size: A3 | Price: 175€';
    const tagFlowers = 'Code: 015 | Size: A3 | Price: 175€';
    const tagWaterShine = 'Code: 016 | Size: A2 | Price: 225€';
    const tagTree = 'Code: 017 | Size: A3 | Price: 175€';

    const description = (
      <>
        <h2>All paths brought us here | Price List</h2>
        <p>
          To order prints contact the gallery through contact@leonardocouto.com with the subject <i>"All paths print order"</i> and inform the <b>code</b> of the art you would like to acquire.
        </p>
        <p>You can also e-mail us in the same address to inquire custom prices for images without the frames or different sizes.</p>
        <br />
      </>
    );


    return (
      <Carousel
        pageName="allpaths"
        description={description}
        tags={[
          tagHand,
          tagHyperspace,
          tagReality,
          tagEye,
          tagGalaxy,
          tagHair,
          tagButterfly,
          tagSnowstorm,
          tagWaterfall,
          tagFox,
          tagPrism,
          tagLines,
          tagCarp,
          tagLivingWater,
          tagFlowers,
          tagWaterShine,
          tagTree
        ]}
        images={[
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/hand.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/hyperspace.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/reality.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/eye.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/galaxy.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/hair.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/butterfly.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/snow-storm.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/waterfall.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/fox.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/prism.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/lines.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/carp.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/living-water.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/flowers.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/water-shine.JPG',
          },
          {
            src: '//s3.eu-central-1.amazonaws.com/lc-portfolio/all-paths/v2/tree.JPG',
          },
        ]}
      />
    );
  }
}

export default AllPathsPage;
